// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash }
        if (savedPosition) return savedPosition
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            component: () => import('@/layouts/home/index.vue'),
            children: [
                {
                    path: '/',
                    name: '/',
                    component: () => import('@/views/home/index.vue'),
                },
                {
                    path: 'home',
                    name: 'home',
                    component: () => import('@/views/home/index.vue'),
                },
                {
                    path: 'about/:pageShow?',
                    name: 'about',
                    component: () => import('@/views/about/index.vue'),
                },
                {
                    path: 'news',
                    name: 'news',
                    component: () => import('@/views/news/index.vue'),
                },
                {
                    path: 'archives/:pageShow?',
                    name: 'archives',
                    component: () => import('@/views/archives/index.vue'),
                },
                {
                    path: 'videos',
                    name: 'videos',
                    component: () => import('@/views/videos/index.vue'),
                },
                {
                    path: 'photos',
                    name: 'photos',
                    component: () => import('@/views/photos/index.vue'),
                },
                {
                    path: 'contact',
                    name: 'contact',
                    component: () => import('@/views/contact/index.vue'),
                },
            ],
        },
    ],
})

export default router
